import React, { useState } from 'react';
import SimpleReactLightbox from 'simple-react-lightbox';
import Layout from '../components/layout';
import SEO from '../components/Seo';

import styled from 'styled-components';
import breakpoints from '../styles/breakpoints';
import usePhotos from '../hooks/usePhotos';
import Gallery from '../components/Gallery';
import Modal from '../components/Modal';
import { windowGlobal } from '../scripts/utils';

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 50px 0 65px 0;

  ${breakpoints.desktop} {
    grid-column: 1 / span 12;

    > div.grid {
      display: grid;
      align-content: center;
      grid-template-columns: repeat(3, 1fr);
      grid-auto-rows: 310px;
      gap: 10px 20px;
      grid-column: 1 / span 12;
      margin-bottom: 0px;
    }
  }
`;

const PAYMENT_SCENARIOS = {
  success: {
    title: '¡Pago exitoso!',
    msg: 'Continúa explorando la tienda',
  },
  error: {
    title: 'Error',
    msg: 'Ocurrió un error en el proceso de pago, intenta de nuevo',
  },
  canceled: {
    title: 'Cancelado',
    msg: 'Proceso de pago cancelado, puedes continuar explorando la tienda',
  },
  default: {
    title: '',
    msg: '',
  },
};

const TiendaPage = () => {
  const { photos } = usePhotos({ showInStore: true });
  const queryString = windowGlobal?.location?.search;
  const urlParams = new URLSearchParams(queryString);
  const paymentStatus = urlParams.get('payment_status');
  const [openModal, setOpenModal] = useState(!!paymentStatus);
  const scenario = PAYMENT_SCENARIOS[paymentStatus];

  const handleOnClose = () => {
    windowGlobal.history.pushState({}, document.title, '/tienda');
    setOpenModal(false);
  };

  return (
    <SimpleReactLightbox>
      <Layout>
        <SEO title="Tienda" />
        <CardContainer>
          <Gallery photos={photos} />
          <Modal
            show={openModal}
            title={scenario?.title}
            message={scenario?.msg}
            onClose={handleOnClose}
          />
        </CardContainer>
      </Layout>
    </SimpleReactLightbox>
  );
};

export default TiendaPage;
