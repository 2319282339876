/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import { SRLWrapper, useLightbox } from 'simple-react-lightbox';
import styled from 'styled-components';
import breakpoints from '../styles/breakpoints';
import Item from './Item';
import { handleImageUrls, windowGlobal } from '../scripts/utils';
import { createCheckoutSession } from '../api/Photos';
import { IMAGE_QUALITIES } from '../scripts/constants';

const StyledGallery = styled.div`
  padding: 0 0 100px;

  .react-slideshow-fade-images-wrap {
    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      width: auto;
      height: 100%;
    }
  }

  ${breakpoints.desktop} {
    padding: 0px;

    .react-slideshow-fade-images-wrap {
      > div {
        height: calc(100% - 1em) !important;
      }
    }
  }
`;

const GalleryWrap = styled.div`
  width: 0px;
  height: 0px;
  overflow: hidden;
`;

const SRLOptions = {
  buttons: {
    showDownloadButton: false,
    showThumbnailsButton: false,
  },
  settings: {
    disablePanzoom: true,
    hideControlsAfter: 1000,
    slideAnimationType: 'both',
  },
  thubmnails: {
    showThumbnails: false,
  },
  caption: {
    showCaption: true,
  },
};

const Gallery = ({ photos = [] }) => {
  const [subPhotos, setSubPhotos] = useState([]);
  const { openLightbox } = useLightbox();

  const handleOnPhotoClick = (photos_) => {
    setSubPhotos(photos_.map(img => ({
      src: handleImageUrls(img.url),
    })));
  };

  const handleOnBuyClick = async ({ price, label, image }) => {
    const { sessionUrl } = await createCheckoutSession({ price, name: label, imageUrl: image });
    windowGlobal.open(sessionUrl, '_blank') || windowGlobal.location.replace(sessionUrl);
  };

  useEffect(() => {
    if (subPhotos.length > 0) {
      setTimeout(openLightbox, 300);
      console.log('%cℹ️ OPEN_LIGHTBOX', 'background: #006579; color: #c2f5ff; font-size: 12px');
    }
    // eslint-disable-next-line
  }, [subPhotos]);

  return (
    <StyledGallery className="grid">
      {/* <button type="button" onClick={() => openLightbox()}>OPEN</button> */}
      {photos.map(({ id, main_photo: mainPhoto, name, subphotos, price }) => (
        <Item
          key={id}
          image={handleImageUrls(mainPhoto?.formats?.[IMAGE_QUALITIES.LARGE].url)}
          label={name}
          onClick={() => handleOnPhotoClick(subphotos)}
          price={price}
          onBuyClick={(item) => handleOnBuyClick(item)}
        />
      ))}
      <GalleryWrap hide>
        <SRLWrapper className="thumbnails" elements={subPhotos} options={SRLOptions} />
      </GalleryWrap>
    </StyledGallery>
  );
};

export default Gallery;
