import React from 'react';
import styled from 'styled-components';

const StyledModal = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #ffffff9c;
  margin: 0;
  padding: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;

  h2 {
    margin: 0.5em 0 0;
  }

  p {
    font-size: 20px;
    color: black;
  }
`;

const Box = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 600px;
  padding: 20px;
  margin: auto;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 0px 150px -40px #000000bf;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 1em;
  cursor: pointer;
`;

const Modal = ({
  show,
  title = 'Title',
  message = 'Some example message description.',
  onClose = () => {},
}) => {
  if (show) {
    return (
      <StyledModal>
        <Box>
          <CloseButton onClick={onClose}>╳</CloseButton>
          <h2>{title}</h2>
          <p>{message}</p>
        </Box>
      </StyledModal>
    );
  }
  return null;
};

export default Modal;
