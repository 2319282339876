import React from 'react';
import styled from 'styled-components';

const Card = styled.div`
  background-image: ${({ image }) => `url(${image})`};
  background-size: cover;

  min-height: 250px;
  height: calc(100% - 2em);
  font-size: 24px;
  cursor: pointer;
`;

const Note = styled.p`
  color: black;
  font-weight: normal;
  text-align: center;
  margin: auto 0;
  padding: 0.5em 0;
  line-height: 1em;

  &.short {
    //
  }

  &.middle {
    //
  }
  
  &.link {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const NoteContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 10px 20% 10px minmax(20%, 1fr);
`;

const StyledItem = styled.div`
`;

const Item = ({ image, label, price = 0, onClick, onBuyClick }) => (
  <StyledItem>
    <Card image={image} onClick={onClick} />
    <NoteContainer>
      <Note>{label}</Note>
      <Note className="short">-</Note>
      <Note className="middle">{`$${price}`}</Note>
      <Note className="short">-</Note>
      <Note className="link" onClick={() => onBuyClick({ image, label, price })}>Comprar</Note>
    </NoteContainer>
  </StyledItem>
);

export default Item;
